
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code2 } from "./data";

export default defineComponent({
  name: "ixed-time-range",
  data() {
    return {
      startTime: "",
      endTime: ""
    };
  },
  components: {
    CodeHighlighter
  },
  setup() {
    return {
      code2
    };
  }
});
